import { Controller } from 'stimulus';

export default class extends Controller {
  get widget() {
    return document.querySelector('.widget');
  }

  get startHour() {
    if (this.widget?.dataset.startHour === undefined) return 15;
    return parseInt(this.widget?.dataset.startHour);
  }

  get endHour() {
    let hour = parseInt(this.widget?.dataset.endHour) || 0;
    return hour === 0 ? 24 : hour;
  }

  get currentHour() {
    return new Date().getHours();
  }

  get body() {
    return document.body;
  }

  connect() {
    this.load();
  }

  load() {
    this.toggleWidget(this.currentHour);
  }

  toggleWidget(hour) {
    const { startHour, endHour, widget, body } = this;
    const isWidgetVisible = hour >= startHour && hour < endHour;

    widget.style.opacity = isWidgetVisible ? '1' : '0';
    body.classList.toggle('widget-page', isWidgetVisible);
  }
}
